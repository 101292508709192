@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles//mixins" as m;
@use "../../../sassStyles/animations" as a;

/* ————————————————————————————————————————————————
  Table Of Contents
  1. Main Code
  2. Breakpoints
  ———————————————————————————————————————————————— */



/* ————————————————————————————————————————————————
  1. Main Code
  ———————————————————————————————————————————————— */

.modal--show {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-x: scroll;
    z-index: 9999;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(33, 18, 2, 0.8);
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.modal--hide {
    display: none;
    transition: all 0.3s ease-out;
}


.modal__content {
    border-top: 3px solid v.$highlight2;
    border-bottom: 3px solid v.$highlight2;
    position: relative;
    background-color: v.$primaryDark2;
    box-shadow: 1px 5px 30px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    width: 90%;
    cursor: default;
    transition: all 0.3s ease-out;
    -webkit-animation: MODAL-FADE-UP 0.5s forwards ease-in-out;
    /* Safari 4+ */
    -moz-animation: MODAL-FADE-UP 0.5s forwards ease-in-out;
    /* Fx 5+ */
    -o-animation: MODAL-FADE-UP 0.5s forwards ease-in-out;
    /* Opera 12+ */
    animation: MODAL-FADE-UP 0.5s forwards ease-in-out;
    /* IE 10+, Fx 29+ */

    @include m.smMinBreakPoint {
        padding: 60px;
        width: 90%;
        max-width: 600px;
    }
}



.modal__close {
    position: absolute;
    top: 15px;
    right: 15px;
    transform: rotate(45deg);
    font-size: 1.3rem;
    color: v.$primary;
    cursor: pointer;
    transition: color .2s ease;
}

.modal__close:hover {
    color: v.$highlight1;
}