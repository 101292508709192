@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles/mixins" as m;

.resources__container {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include m.mdMinBreakPoint {
        position: relative;
        padding: 200px 0;
        justify-content: normal;
    }
}

.resources__image-container {

    @include m.mdMinBreakPoint {
        width: 100%;
    }


}

.resources__content-column {
    max-width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include m.mdMinBreakPoint {
        position: absolute;
        right: 15px;
        align-items: flex-start;
    }
}


.resources__headline {
    max-width: 300px;
    text-align: center;
    color: v.$secondary1;

    @include m.mdMinBreakPoint {
        text-align: left;
    }
}

.resources__tab-container {
    padding: 0;
}