//MODA
@-webkit-keyframes MODAL-FADE-UP {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-moz-keyframes MODAL-FADE-UP {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-o-keyframes MODAL-FADE-UP {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes MODAL-FADE-UP {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2021-4-1 16:18:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-loop
 * ----------------------------------------
 */
@-webkit-keyframes fade-loop {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-loop {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



// backgroundImgChangeFadeInOut
// Original solution http://jsfiddle.net/k2dzLxq4/1/

@keyframes backgroundImgChangeFadeInOut {
    0% {
        opacity: 1;
    }

    17% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    92% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes backgroundImgChangeFadeInOut {
    0% {
        opacity: 1;
    }

    17% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    92% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}




/* ----------------------------------------------
 * Generated by Animista on 2021-4-5 20:9:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}