@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles/mixins" as m;

.contact-form__confirmation-container {
    text-align: center;
    font-weight: normal;
    color: v.$secondary1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: v.$primaryDark3;
    padding: 20px;
    margin-bottom: 20px;
    transition: 0.3s ease-in-out;

    @include m.smMinBreakPoint {
        padding: 50px;
    }

    h3 {
        margin-bottom: 10px;
        color: v.$secondary1;
    }

    p {
        margin-top: 0;
        color: v.$secondary2;

    }
}