@use "../../../sassStyles/variables" as v;
@use "../../../sassStyles/mixins" as m;

.feedback___card {
    max-width: 240px;
    box-shadow: 0 13px 24px rgba(0, 0, 0, 0.2);
    background-color: v.$primaryDark3;
    border-top: 5px solid v.$highlight2;
    padding: 20px;
    text-align: center;
}

.feedback___card {
    margin: 0;
    color: v.$highlight2;
    ;
}

.feedback___card {
    margin: 14px 0 0;
    color: v.$secondary1;
    ;
}