// Screen sizes
$screen-sm: 676px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$primary: #996e01;
$primaryDark1: #713d72;
$primaryDark2: #704371;
$primaryDark3: #4f3750;
$secondary1: #D0C6AC;
$secondary2: #D6C089;
$highlight1: #FFBB0B;
$highlight2: #00B7BD;
$warning: #e3190e;